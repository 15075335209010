import React, { useState, useEffect } from 'react';

const loyaltyTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(false);

  const copyStrings = {
    en: {
      heading: 'What’s my Fresh Rewards tier?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Fresh Start',
      tableColDesc1: '0-500 Points',
      tableColHeader2: 'Fresh Plus',
      tableColDesc2: '501-1000 Points',
      tableColHeader3: 'Fresh Elite',
      tableColDesc3: '1001+ Points',
      tableRowDesc1: 'Early access to new products',
      tableRowDesc2: 'Birthday voucher',
      tableRowDesc3: 'Contests',
      tableRowDesc4: 'Free travel gift',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td>&bull;</td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td>&bull;</td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td></td>
              <td>&bull;</td>
              <td>&bull;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td></td>
              <td></td>
              <td>&bull;</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default loyaltyTiers;

import React, { useState, useEffect } from 'react';

const BonLookHowItWorks = ({ lang }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const copyStrings = {
    en: {
      heading: 'How does this work?',
    },
  };

  const instructions = {
    en: [
      {
        id: 1,
        title: 'Join',
        description: 'Sign up by entering your email.',
      },
      {
        id: 2,
        title: 'Check your email',
        description: 'You’ll get an email from us with your login link. Click through to validate your account and explore your membership.',
      },
      // {
      //   id: 3,
      //   title: 'Start shopping',
      //   description: 'Start exploring different styles and get rewarded when you purchase frames and accessories.',
      // },
      {
        id: 4,
        title: 'Access perks',
        description: 'Get rewarded for exploring your style: exclusive events, contests and early access to new drops',
      },
      // {
      //   id: 5,
      //   title: 'Evolve',
      //   description: 'The more you shop, the closer you get to levelling up.',
      // },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          {copyStrings[lang].heading}
        </div>
        <div className="how-it-works__list">
          {instructions[lang].map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default BonLookHowItWorks;

import React, { useState, useEffect } from 'react';
import CollectionHero from '../collection/collectionHero';
import DigitalAssets from '../collection/digitalAssets';
import AccountManager from '../../managers/Account';
import UtilityManager from '../../managers/Utility';
import CollectionUtility from '../collection/collectionUtility';
import Throbber from '../throbber';

function AccountCollection() {
  // State variables using useState
  const [brandId, setBrandId] = useState(null);
  const [allUtility, setAllUtility] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredUtility, setFilteredUtility] = useState([]);
  const [tab, setTab] = useState(0);
  const [filterLabel, setFilterLabel] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [assets, setAssets] = useState([]);

  const goToMembershipPage = (asset) => {
    console.log('=== ONLY ONE ASSET ===');
    console.log(asset);
    if (asset.nftId) {
      window.location = `/asset/${asset.nftId}`;
    } else {
      window.location = `/membership/${asset.loyaltyMembership.userAssetPointsId}`;
    }
  };

  const getMembership = async (brandId) => {
    const assets = await AccountManager.getFlatCollection(AccountManager.getToken(), brandId);
    if (assets && assets.length === 1) {
      goToMembershipPage(assets[0]);
    } else if (assets && assets.length > 1) {
      setAssets(assets);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const getAllBrandUtility = async () => {
    // TODO: on component mount, attempt to get all the utililty for a brand
    const allUtility = await UtilityManager.getAllUtility(process.env.CREATOR_ID, AccountManager.getToken());
    if (allUtility && allUtility.utility) {
      setAllUtility(allUtility.utility.loyaltyUtility);
      // setAllUtility(allUtility.utility.collectionUtility.concat(allUtility.utility.nftUtility));
    }
  };

  const setUtilityFilter = async (filterType, filterLabel) => {
    setTab(filterType);
    setFilterLabel(filterLabel);
    const filterResults = UtilityManager.sortUtility(process.env.CREATOR_ID, filterType, AccountManager.getToken());
    setFilteredUtility(filterResults);
  };

  const updateFilterOptions = () => {
    const parsedFilterOptions = [];
    // if (allUtility.filter((e) => e.utilityType === 3).length > 0) {
    //   parsedFilterOptions.push({
    //     id: 1,
    //     label: 'Merch',
    //   });
    // }
    if (allUtility.filter((e) => e.utilityType === 2).length > 0) {
      parsedFilterOptions.push({
        id: 2,
        label: 'Media',
      });
    }
    if (allUtility.filter((e) => e.utilityType === 4 || e.utilityType === 1 || e.utilityType === 3).length > 0) {
      parsedFilterOptions.push({
        id: 3,
        label: 'Offers',
      });
    }
    if (allUtility.filter((e) => e.utilityType === 6).length > 0) {
      parsedFilterOptions.push({
        id: 6,
        label: 'Contests',
      });
    }
    setFilterOptions(parsedFilterOptions);
  };

  useEffect(() => {
    setBrandId(process.env.CREATOR_ID);
    getMembership(process.env.CREATOR_ID);
    getAllBrandUtility(process.env.CREATOR_ID);
    if (!AccountManager.isLoggedIn()) {
      window.location = '/';
    }
  }, []);

  useEffect(() => {
    updateFilterOptions();
  }, [allUtility]);

  return (
    <>
      {isLoading ? (
        <Throbber throbberText="Looking for your membership! One second..." />
      )
        : (
          <div className="account-collection">
            {brandId && <CollectionHero brandId={brandId} filterOptions={filterOptions} onFilterSelect={(filterType, filterLabel) => setUtilityFilter(filterType, filterLabel)} />}
            <div className="account-collection__body">
              {tab === 0
              && (
              <DigitalAssets
                brandId={brandId}
                assets={assets} />
              )}
              {filteredUtility && tab !== 0
             && <CollectionUtility utilityType={tab} utilityLabel={filterLabel} utilityList={filteredUtility} />}
            </div>
          </div>
        )}
    </>
  );
}

export default AccountCollection;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import AnnouncementsManager from '../../managers/Announcements';

function LoyaltyDisplayWidget({ creatorId, tier }) {
  const [announcements, setAnnouncements] = useState([]);
  const [percentage, setPercentage] = useState(0);

  const tierLegend = [{
    index: 0,
    variationName: 'Fresh Start',
    value: 0,
  },
  {
    index: 1,
    variationName: 'Fresh Plus',
    value: 40,
  },
  {
    index: 2,
    variationName: 'Fresh Elite',
    value: 90,
  },
  ];

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      setAnnouncements(data.announcements);
    }
  };

  const handleStickyWidget = () => {
    const stickyBrand = document.getElementById('brandWidget');
    const benefitsSection = document.getElementById('benefitsPanel');
    let stickyStop = 1400;
    console.log(benefitsSection);
    console.log(benefitsSection.clientHeight);
    if (benefitsSection) {
      stickyStop = benefitsSection.clientHeight + 410;
    }
    window.addEventListener('scroll', () => {
      if ((stickyBrand.clientHeight + 200) < window.innerHeight) {
        if (window.scrollY > 730 && window.scrollY < stickyStop) {
          stickyBrand.classList.add('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        } else if (window.scrollY > stickyStop) {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.add('-psuedo-fixed');
        } else {
          stickyBrand.classList.remove('-fixed');
          stickyBrand.classList.remove('-psuedo-fixed');
        }
      } else {
        stickyBrand.classList.remove('-fixed');
        stickyBrand.classList.remove('-psuedo-fixed');
      }
    });
  };

  const setTierPercentage = () => {
    tierLegend.forEach((item) => {
      if (item.variationName === tier.variationName) setPercentage(item.value);
    });
  };

  useEffect(() => {
    getAnnouncements();
    setTimeout(() => {
      handleStickyWidget();
    }, 1000);
    setTierPercentage();
  }, []);

  return (
    <div id="brandWidget" className="brand-widget -loyalty-widget">
      {/* <div className="brand-widget__logo">
        {process.env.LOGO_INVERSE
          ? <img src={process.env.LOGO_INVERSE} alt={process.env.SITE_NAME} />
          : <img src={process.env.LARGE_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />}
        
      </div> */}
      <div className="brand-widget__loyalty-progress">
        {/* <div className="brand-widget__progress-container">
          <div className="brand-widget__progress-bar">
            
          </div>
        </div> */}
        <div className="brand-widget__progress-bar">
          <div className="brand-widget__progress-bar-overflow">
            <img className="progress-bar_start" src="https://assets.heyethos.com/kindlaundry/meter-cap-transparent.png" alt="Loyalty meter cap" />
            <div className="progress-bar__label -slot1">Fresh Start</div>
            <div className="progress-bar__label -slot2">Fresh Plus</div>
            <div className="progress-bar__label -slot3">Fresh Elite</div>
            <img className="progress-bar_end" src="https://assets.heyethos.com/kindlaundry/meter-cap-transparent.png" alt="Loyalty meter cap" />
            <div
                className="brand-widget__progress-bar-bar"
                style={{
                  transform: 'rotate(' + (45 + (percentage * 1.8)) + 'deg)',
                }}>
            </div>
          </div>
          <div className="brand-widget__progress-label">200 pts</div>
        </div>
      </div>
      <p>Spend $204 to gain access to Heritage Member offers!</p>
      {/* <div className="brand-widget__tier-info">
        <div className="brand-widget__tier-title">{tier ? tier.variationName : 'Unknown'}</div>
      </div> */}
      {/* <div className="brand-widget__description">{process.env.SITE_DESCRIPTION}</div> */}
      <a href={process.env.CLIENT_WEBSITE} target="_blank" className="brand-widget__button" rel="noreferrer">Go to website</a>
      {announcements.length > 0
      && (
      <div className="brand-widget__updates">
        <div className="brand-widget__updates--header">
          Updates from
          {' '}
          {process.env.SITE_NAME}
        </div>
        <div className="brand-widget__updates--container">
          {announcements.map((announcement) => (
            <div
              key={announcement.announcementId}
              className="brand-widget__updates--item">
              <div dangerouslySetInnerHTML={{ __html: announcement.textContent }}></div>
              <div className="brand-widget__updates--date">
                {moment(announcement.dateCreated).format('MMM DD, YYYY')}
              </div>
            </div>
          ))}
        </div>
        
        {/* <Link to="/account/collection" className="brand-widget__button">View more</Link> */}
      </div>
      )}
    </div>
  );
}

export default LoyaltyDisplayWidget;
